import React from 'react';
import type { PageProps } from 'gatsby';

import PageLayout from '../../../views/Layout/Page';
import MyVehiclesPage from '../../../views/Vehicle/MyVehiclesPage';
import MyVehicleTab from '../../../views/Vehicle/MyVehicleTabs';

export default function CarsDetailPage({ params }: PageProps) {
  return (
    <PageLayout pageview="my_cars_basic_info" preloadEnums={['fuel', 'manufacturers', 'transmission', 'type']} private>
      <MyVehiclesPage hash={params.hash} tab={MyVehicleTab.BASIC_INFO} />
    </PageLayout>
  );
}
